const INITIAL_STATE = {
    currentPageName: { values: [], error: null, loading: false },
  };
  
  export default function (state = INITIAL_STATE, action) {
    return {
      ...state,
      currentPageName: { values: action.payload, error: null, loading: true },
    };
  }
  