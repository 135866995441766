const INITIAL_STATE = {
  payment: {
    values: {
      organizationnumber: "",
      organizationname: "",
      postalcode: "",
      city: "",
      name: "",
      emailId: "",
      checkoutRef: "",
    },
    error: null,
    loading: false,
  },
};

export default function (state = INITIAL_STATE, action) {
  return {
    ...state,
    payment: {
      values: {
        organizationnumber: "",
        organizationname: "",
        postalcode: "",
        city: "",
        name: "",
        emailId: "",
        checkoutRef: "",
      },
      error: null,
      loading: true,
    },
  };
}
