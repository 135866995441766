import LocalTomcatConfig from "./LocalTomcatConfig";
import ServerTomcatConfig from "./ServerTomcatConfig";
import DevConfig from "./DevConfig";

//dev,local,server
const useConfig = "server";

let config;

if (useConfig == "local") {
  config = LocalTomcatConfig;
} else if (useConfig == "server") {
  config = ServerTomcatConfig;
} else if (useConfig == "dev") {
  config = DevConfig;
}

export default config;
