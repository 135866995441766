import React, { useState, useRef } from "react";
import "../ContactUs/ContactUs.css";
import { Toast } from "primereact/toast";
import axios from "axios";
import TranslationFile from "../Translation/Translation.json";
import config from "../../Configuration/Config";
import FAQSection from "./FAQSection";

let userSelectedLanguage;
export default function ContactUs() {
  userSelectedLanguage = localStorage.getItem("selectedLanguage");
  let userTimeZone = localStorage.getItem("userTimeZone");
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [numberValue, setNumberValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [file, setFile] = useState("");
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  function handleFile(event) {
    setFile(event.target.files[0]);
  }

  const toast = useRef(null);
  const inputFieldsOnBlur = (e, fieldName) => {
    let value = e.target.value;

    switch (fieldName) {
      case "name":
        setNameValue(value);
        break;

      case "email":
        setEmailValue(value);
        break;

      case "contact":
        setNumberValue(value);
        break;

      case "message":
        setMessageValue(value);
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let fileSizeInBytes = 0;
    if (file != "") {
      fileSizeInBytes = file.size;
    }
    const fileSizeInKilobytes = fileSizeInBytes / 1024;
    const fileSizeInMegabytes = fileSizeInKilobytes / 1024;
    if (fileSizeInMegabytes <= 2) {
      setSubmitBtnLoading(true);
      try {
        let sendEmailObject = {
          emailType: "contactUs",
          contactMail: emailValue,
          status: "New",
          noOfAttempts: 0,
          message: messageValue,
          userName: nameValue,
          contactNumber: numberValue,
          filename: file.name,
        };

        const url = "/emailQueueController/insertEmailQueueAndSendMail";
        const formData = new FormData();
        const objString = JSON.stringify(sendEmailObject);

        formData.append("file", file);
        formData.append("request", objString);
        const config = {
          headers: {
            "content-type": "multipart/form-data,application/json",
            timeZone: userTimeZone,
          },
        };

        axios.post(url, formData, config).then((response) => {
          if (response.data) {
            toast.current.show({
              severity: "success",
              summary: TranslationFile.SuccessText[userSelectedLanguage],
              detail: TranslationFile.ContactSubmitReplyt[userSelectedLanguage],
              sticky: true,
            });
            setSubmitBtnLoading(false);
            setTimeout(() => {
              toast.current.clear();
              e.target.reset();
            }, 5000);
          }
        });
      } catch {}
    } else {
      toast.current.show({
        severity: "warn",
        summary: TranslationFile.ToastWarningHeader[userSelectedLanguage],
        detail:
          TranslationFile.ContactUsFileSizeWarningMsg[userSelectedLanguage] +
          config.ContactUs.uploadFileSizeLimit +
          " MB",
        sticky: true,
        life: 3000,
      });
    }
    setFile("");
  };

  return (
    <div className="contact-faq-whole">
      <div className="contact-container">
        <div className="our-contact-form">
          <div className="corp-form">
            <span className="circle one"></span>
            <span className="circle two"></span>

            <form onSubmit={handleSubmit} className="contact-us-form">
              <Toast
                ref={toast}
                position="top-right"
                style={{ marginTop: "80px" }}
              />
              <center>
                <h3 className="home-contact-title">
                  {" "}
                  {TranslationFile.ContactUsHeader[userSelectedLanguage]}
                </h3>
                <br></br>
                <div className="contact-us-form-inside">
                  <input
                    type="text"
                    name="name"
                    placeholder={
                      TranslationFile.ContactUsName[userSelectedLanguage]
                    }
                    className="contact-Name-User"
                    autocomplete="off"
                    required
                    oninvalid="this.setCustomValidity('Please Enter your Name')"
                    oninput="this.customerValidity('')"
                    onBlur={(e) => inputFieldsOnBlur(e, "name")}
                  />
                  <br></br>
                  <br></br>

                  <input
                    type="email"
                    name="email"
                    placeholder={
                      TranslationFile.EmailLabel[userSelectedLanguage]
                    }
                    className="contact-Email-User"
                    autocomplete="off"
                    required
                    oninvalid="this.setCustomValidity('Please Enter a valid Email')"
                    oninput="this.customerValidity('')"
                    onBlur={(e) => inputFieldsOnBlur(e, "email")}
                  />
                  <br></br>
                  <br></br>

                  <input
                    type="text"
                    name="Contact Number"
                    placeholder={
                      TranslationFile.ContactUsNumber[userSelectedLanguage]
                    }
                    className="contact-ContactNo-User"
                    autocomplete="off"
                    required
                    oninvalid="this.setCustomValidity('Please Enter your Contact Number')"
                    oninput="this.customerValidity('')"
                    onBlur={(e) => inputFieldsOnBlur(e, "contact")}
                  />
                  <br></br>
                  <br></br>

                  <textarea
                    name="message"
                    placeholder={
                      TranslationFile.ContactUsMessage[userSelectedLanguage]
                    }
                    className="contact-Message-User"
                    autocomplete="off"
                    required
                    oninvalid="this.setCustomValidity('Please Enter your Message')"
                    oninput="this.customerValidity('')"
                    onBlur={(e) => inputFieldsOnBlur(e, "message")}
                  ></textarea>
                  <br></br>
                  <br></br>

                  <input
                    type="file"
                    name="file"
                    id="input_file"
                    onChange={handleFile}
                    className="contact-file-upload"
                  />
                  <br></br>
                  <p className="contactUsMaxUploadSize">
                    {TranslationFile.ContactUsFileSizeLbl[userSelectedLanguage]}
                    {config.ContactUs.uploadFileSizeLimit}
                    {" MB)"}
                  </p>
                </div>
              </center>
              <center>
                {submitBtnLoading ? (
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "2rem" }}
                  ></i>
                ) : (
                  <button type="submit" className="contact-message-submit">
                    {TranslationFile.ContactUsSubmitBtn[userSelectedLanguage]}
                  </button>
                )}
              </center>
              <br></br>
              <center>
                <p className="contactUsEmailLastLine">
                  Email us at{" : "}
                  <a href="mailto:info@rebelskool.se" id="email_contact_text">
                    info@rebelskool.se
                  </a>
                </p>
              </center>
            </form>
          </div>
        </div>
      </div>
      <div>
        <FAQSection />
      </div>
    </div>
  );
}
