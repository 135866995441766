import { combineReducers } from "redux";
import annualReportType from "./reducer-annualReportType";
import companyInformation from "./reducer-companyInformation";
import financialYear from "./reducer-financialYear";
import incomeStatement from "./reducer-incomeStatement";
import balanceSheet from "./reducer-balanceSheet";
import notes from "./reducer-notes";
import managementStatus from "./reducer-managementStatus";
import sessionDetails from "./reducer-sessionDetails";
import sideBarReducer from "./reducer-sideBar";
import login from "./reducer-login";
import language from "./reducer-language";
import activeSideBar from "./reducer-activeSideBar";
import prices from "./reducer-prices";
import currentPageName from "./reducer-currentPageName";
import pricesToLogin from "./reducer-pricesToLogin";
import readOnly from "./reducer-readOnly";
import showWIPStatus from "./reducer-showWIPStatus";
import payment from "./reducer-payment";

const rootReducer = combineReducers({
  annualReportType: annualReportType,
  companyInformation: companyInformation,
  financialYear: financialYear,
  incomeStatement: incomeStatement,
  sessionDetails: sessionDetails,
  sideBarReducer: sideBarReducer,
  login: login,
  language: language,
  activeSideBar: activeSideBar,
  prices: prices,
  balanceSheet: balanceSheet,
  notes: notes,
  managementStatus: managementStatus,
  currentPageName: currentPageName,
  pricesToLogin: pricesToLogin,
  readOnly: readOnly,
  showWIPStatus: showWIPStatus,
  payment: payment,
});

export default rootReducer;
