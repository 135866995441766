import React, { useState, useEffect } from "react";
import "./index.css";
import { Provider } from "react-redux";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers/index";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css"; //icons
import "primeflex/primeflex.css";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import CorporatePage from "./Pages/CorporatePage/CorporatePage";
import ContactUs from "./Pages/ContactUs/ContactUs";
import HeaderNavBar from "./Pages/HeaderNavBar/HeaderNavBar";
import ImageSlider from "./Pages/ImageSlider/ImageSlider";
import Credits from "./Pages/Footerpage/Credits";
import BusinessCentral from "./Pages/BusinessCentral/BusinessCentral";
import ForAccountingCompanies from "./Pages/ForAccountingCompanies/ForAccountingCompanies";
import WhyRebelSkool from "./Pages/AboutUs/WhyRebelSkool";
import PricePolicy from "./Pages/AboutUs/PricePolicy";
import AboutUs from "./Pages/AboutUs/AboutUs";
import References from "./Pages/References/References.js";
import ForInvestors from "./Pages/ForInvestors/ForInvestors.js";
import DataSecurity from "./Pages/DataSecurity/DataSecurity.js";

let uuid = "";
let store = createStore(reducers, applyMiddleware(thunk));
const propsStore = store.getState();
export default function App() {
  const [rerender, setRerender] = useState(false);
  let currentReportGUID = "";
  useEffect(() => {
    //Setting default Language for user
    let langFromLS = localStorage.getItem("selectedLanguage");
    if (langFromLS == undefined || langFromLS == "" || langFromLS == null) {
      localStorage.setItem("selectedLanguage", "sw");
    }

    //Getting Data From Status Band Table
    axios.get("/siteOwner/getStatusBand?appId=0").then((response) => {
      let respData = response.data;
      localStorage.setItem("StatusBand", JSON.stringify(respData));
    });

    //Getting User's Timezone in GMT Format and setting it in Local Storage
    const userDate = new Date();
    const offsetMinutes = userDate.getTimezoneOffset();
    const offsetHours = Math.abs(offsetMinutes / 60);
    const offsetHoursString = String(offsetHours).padStart(2, "0");
    const offsetHoursStringWithoutMinutes = offsetHoursString.split(".")[0];
    const offsetMinutesString = String(Math.abs(offsetMinutes % 60)).padStart(
      2,
      "0"
    );
    const offsetSign = offsetMinutes < 0 ? "+" : "-";
    const userTimezone = `GMT${offsetSign}${offsetHoursStringWithoutMinutes}:${offsetMinutesString}`;
    localStorage.setItem("userTimeZone", userTimezone);

    let navigateToGUID = localStorage.getItem("navigateToGUID");
    axios.get("/cms/generateUUID").then((response) => {
      let currentPageUrl = window.location.href;
      let splitCurrentPageUrl = currentPageUrl.split("/");
      if (splitCurrentPageUrl.length == 7) {
        uuid = splitCurrentPageUrl[5];
      } else {
        uuid = response.data;
      }

      if (currentReportGUID != "") {
        uuid = currentReportGUID;
      }

      if (navigateToGUID != "" && navigateToGUID != null) {
        uuid = navigateToGUID;
      }
      let sessionDetails = {
        uuid: uuid,
        currentPage: "home",
        IsAnnualReportSubmitted: false,
      };
      propsStore["sessionDetails"]["sessionDetails"].values = sessionDetails;
      localStorage.setItem("sessionUUID", uuid);
      setRerender(true);
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={CorporatePage} />
          <Route exact path="/contact-us/" component={CorporatePage} />
          <Route exact path="/imageSlider" component={ImageSlider} />
          <Route exact path="/headerNavBar" component={HeaderNavBar} />
          <Route exact path="/contactUs" component={ContactUs} />
          <Route exact path="/credits" component={Credits} />
          <Route
            exact
            path="/for-accounting-companies/"
            component={ForAccountingCompanies}
          />
          <Route
            exact
            path="/microsoft-business-central-development/"
            component={BusinessCentral}
          />
          <Route exact path="/about-us" component={AboutUs} />
          <Route
            exact
            path="/about-us/why-RebelSkool"
            component={WhyRebelSkool}
          />
          <Route exact path="/data-security/" component={DataSecurity} />
          <Route exact path="/customer-references/" component={References} />
          <Route exact path="/for-investors/" component={ForInvestors} />
          <Route exact path="/about-us/Price-Policy" component={PricePolicy} />
        </Switch>
      </Router>
    </Provider>
  );
}
