import React, { useState, useEffect } from "react";
import { Carousel } from "primereact/carousel";
import "./ImageSlider.css";
import TranslationFile from "../Translation/Translation.json";
import image1 from "../../Assests/image1.png";
import image2 from "../../Assests/image2.png";
import image3 from "../../Assests/image3.png";

let userSelectedLanguage;

const slideList = [
  {
    id: 1,
    img: image1,
  },
  {
    id: 2,
    img: image2,
  },
  {
    id: 3,
    img: image3,
  },
];

export default function ImageSlider() {
  userSelectedLanguage = localStorage.getItem("selectedLanguage");
  const slideListTemplate = (slideList) => {
    return (
      <div>
        <img src={slideList.img} className="demo-slide-image"></img>
      </div>
    );
  };
  return (
    <div className="caro-demo">
      <div>
        <div>
          <div className="caro-demo-slide">
            <center>
              <br></br>
              <h4 className="demo-title-heading">
                {TranslationFile.ImageSlider1[userSelectedLanguage]}
                <br></br>
                <br></br>
                {TranslationFile.ImageSlider2[userSelectedLanguage]}
              </h4>
            </center>
            <center>
              <Carousel
                value={slideList}
                numVisible={1}
                numScroll={1}
                className="demo-work-carousel"
                circular={false}
                autoplayInterval={3000}
                itemTemplate={slideListTemplate}
              />
            </center>
          </div>
        </div>
      </div>
    </div>
  );
}
