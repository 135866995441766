const INITIAL_STATE = {
  notes: { values: [], error: null, loading: false },
  notesTemplateReqArrayReducer: { values: [], error: null, loading: false },
};

export default function (state = INITIAL_STATE, action) {
  return {
    ...state,
    notes: { values: action.payload, error: null, loading: true },
    notesTemplateReqArrayReducer: {
      values: action.payload,
      error: null,
      loading: true,
    },
  };
}
