import React, { Component } from "react";
import { connect } from "react-redux";
import { StatusALert } from "../StatusAlert/StatusAlert";
// import "react-sticky-header/styles.css";
import { Button } from "primereact/button";
import TranslationFile from "../Translation/Translation.json";
import ContactUs from "../ContactUs/ContactUs";
import Footer from "../Footerpage/Footer";
import "./CorporatePage.css";
import $ from "jquery";
import axios from "axios";
// import Lap from "../../Assests/02.jpg";
import Lap from "../../Assests/carousel1.webp";
import { Helmet } from "react-helmet";
import HeaderNavBar from "../HeaderNavBar/HeaderNavBar";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import ImageSlider from "../ImageSlider/ImageSlider";
import config from "../../Configuration/Config";

const mapStateToProps = (state) => {
  return {
    sessionDetails: state.sessionDetails,
  };
};

let userSelectedLanguage;
class CorporatePage extends Component {
  constructor(props) {
    super(props);
    this.contactRef = React.createRef();
    this.whyRSRef = React.createRef();
    this.pricePolicyRef = React.createRef();
    this.dataSecRef = React.createRef();
    this.servicesRef = React.createRef();

    this.state = {
      isNavExpanded: false,
      dummy: false,
      matches: window.matchMedia("(min-width: 1019px)").matches,
      sidebarVisible: false,
      color: false,
      basicPrice: "",
    };
  }

  refresh() {
    setTimeout(() => {
      window.location.reload(false);
    }, 10);
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    userSelectedLanguage = localStorage.getItem("selectedLanguage");

    $(document).ready(function () {
      $(window).scroll(function () {
        var height = $(".first-container").height();
        var scrollTop = $(window).scrollTop();

        if (scrollTop >= height - 40) {
          $(".nav-container").addClass("solid-nav");
        } else {
          $(".nav-container").removeClass("solid-nav");
        }
      });
    });
  }

  componentDidMount() {
    this.scrollTo();
    axios.get("/siteOwner/getTemplatePackages").then((response) => {
      let getFilteredPackagesList = response.data.filter(
        (obj) => obj.visibleInGrid
      );

      // const getlowestPriceObject = getFilteredPackagesList.reduce(
      //   (prev, current) => {
      //     return prev.price < current.price ? prev : current;
      //   }
      // );

      const getlowestPriceObject = getFilteredPackagesList[0];

      this.setState({
        basicPrice: getlowestPriceObject.price,
      });
    });
  }

  annualReport() {
    const { sessionDetails } = this.props;
    const { activeSideBar } = this.props;
    let selectedSideBar = [];

    setTimeout(() => {
      console.log(sessionDetails, ">> SD");
      sessionDetails["sessionDetails"].values.currentPage = "companyInfo";
      sessionDetails["sessionDetails"].values.IsAnnualReportSubmitted = false;

      const annualReport = "/annualreport/";
      let sessionUUIDFromLS = localStorage.getItem("sessionUUID");
      window.location.href =
        config.apps.url +
        "#" +
        annualReport +
        sessionUUIDFromLS +
        "/companyInfo";

      // this.props.history.push(
      //   annualReport +
      //     sessionDetails["sessionDetails"].values.uuid +
      //     "/companyInfo"
      // );
    }, 1000);
  }

  scrollTo() {
    let currentPageUrl = window.location.href;
    let splitCurrentPageUrl = currentPageUrl.split("/");
    if (splitCurrentPageUrl[splitCurrentPageUrl.length - 2] == "contact-us") {
      if (!this.contactRef.current) return;
      this.contactRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });

      $(".corp-body").removeClass("open-menu");
      $(".hamburger-menu .bar").removeClass("animate");
    } else if (
      splitCurrentPageUrl[splitCurrentPageUrl.length - 2] == "data-security" ||
      splitCurrentPageUrl[splitCurrentPageUrl.length - 2] ==
        "customer-references" ||
      splitCurrentPageUrl[splitCurrentPageUrl.length - 2] == "for-investors"
    ) {
      if (!this.dataSecRef.current) return;
      this.dataSecRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      $(".corp-body").removeClass("open-menu");
      $(".hamburger-menu .bar").removeClass("animate");
    } else if (
      splitCurrentPageUrl[splitCurrentPageUrl.length - 2] ==
        "for-accounting-companies" ||
      splitCurrentPageUrl[splitCurrentPageUrl.length - 2] ==
        "microsoft-business-central-development"
    ) {
      if (!this.servicesRef.current) return;
      this.servicesRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      $(".corp-body").removeClass("open-menu");
      $(".hamburger-menu .bar").removeClass("animate");
    } else if (
      splitCurrentPageUrl[splitCurrentPageUrl.length - 1] ==
      "about-us#Why-RebelSkool"
    ) {
      if (!this.whyRSRef.current) return;
      this.whyRSRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      $(".corp-body").removeClass("open-menu");
      $(".hamburger-menu .bar").removeClass("animate");
    } else if (
      splitCurrentPageUrl[splitCurrentPageUrl.length - 1] ==
      "about-us#Price-Policy"
    ) {
      if (!this.pricePolicyRef.current) return;
      this.pricePolicyRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      $(".corp-body").removeClass("open-menu");
      $(".hamburger-menu .bar").removeClass("animate");
    }
  }

  showStatusAlertFunc = (val) => {
    this.props.showStatusAlertFunc(true);
  };

  callback = () => {
    this.scrollTo();
    this.setState({
      reRender: true,
    });
  };

  render() {
    const { basicPrice } = this.state;

    if (userSelectedLanguage == null) {
      userSelectedLanguage = "sw";
    }

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {userSelectedLanguage == "sw"
              ? TranslationFile.FaviconCorpText[userSelectedLanguage]
              : TranslationFile.FaviconCorpText.en}
          </title>
        </Helmet>
        <div>
          <HeaderNavBar
            callBack={this.callback}
            isAppPortal={false}
            isCorpPortal={true}
            isHomePage={false}
          />
          {/* <div
            class="first-container"
            style={{ backgroundImage: `url(${Lap})` }}
          ></div> */}
          <img src={Lap} class="first-container"></img>
        </div>

        {/* ----------------------------------------------------------------- */}
        <div className="hero-container">
          <div className="overlay"></div>
          <div className="hero-content">
            <h3 className="hero-para-h3">
              <div className="corp_text_1">
                {/* Now you can do */}
                {TranslationFile.CorpPageNowYoucando[userSelectedLanguage]}
                <br></br>
                {/* ANNUAL REPORT Online  */}
                {
                  TranslationFile.CorpPageAnuualReportOnline[
                    userSelectedLanguage
                  ]
                }
                <br></br>
              </div>
              <div className="corp-price-tag-color">
                {TranslationFile.onlyfor[userSelectedLanguage]} {basicPrice} kr
              </div>
            </h3>
            {/* <p className="hero-para"></p> */}
            <div className="hero-btns">
              <Button
                className="corp-btn-starts"
                onClick={() => this.annualReport()}
              >
                {/* Try For Free */}
                {TranslationFile.NavbarTryForFree[userSelectedLanguage]}
              </Button>
            </div>
          </div>
        </div>
        {/* ------------------------------------------------------------------- */}

        <div className="about-div-home">
          <ImageSlider />
        </div>

        {/* ------------------------------------------------------------------- */}

        <div
          class="about-service"
          //  ref={this.servicesRef}
        >
          <div>
            <div>
              <h4>
                {/* For Accounting Companies */}
                {
                  TranslationFile.FooterForAccountingCompany[
                    userSelectedLanguage
                  ]
                }
              </h4>
            </div>
            <div className="about-service-paraDiv">
              So we’ve made back-office services as efficient and seamless as
              possible. Because when it all just works, you’re free to focus on
              moving your business forward. As serial entrepreneurs, we
              understand the empowerment that comes when you have confidence
              around your financial health. Improving cash flow and
              profitability can happen when you turn that confidence into
              action. We invest in accurate financial data and actionable
              insights so you can spend more time in your genius zone.
              <a href="/for-accounting-companies">more details</a>
            </div>
          </div>
        </div>

        {/* ------------------------------------------------------------------ */}
        <div className="services-section" ref={this.dataSecRef}>
          <h2>
            {/* FAQ / References */}
            {TranslationFile.FooterFAQReferences[userSelectedLanguage]}
          </h2>
          <div className="services-list">
            <div className="service-item">
              <h3>
                {/* Data Security */}
                {TranslationFile.FooterDataSecurity[userSelectedLanguage]}
              </h3>
              <p>
                lorem ipsum dolor sit amet, consectetur adipiscing elit, lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore lorem ipsum dolor
                sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua.
                <a href="/data-security">more details</a>
              </p>
            </div>
            <div className="service-item">
              <h3>
                {/* References */}
                {TranslationFile.NavbarReferences[userSelectedLanguage]}
              </h3>
              <p>
                lorem ipsum dolor sit amet, consectetur adipiscing elit, lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore lorem ipsum dolor
                sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua.
                <a href="/customer-references">more details</a>
              </p>
            </div>
            <div className="service-item">
              <h3>
                {/* For Investors */}
                {TranslationFile.FooterForInvestors[userSelectedLanguage]}
              </h3>
              <p>
                lorem ipsum dolor sit amet, consectetur adipiscing elit, lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore lorem ipsum dolor
                sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua.
                <a href="/for-investors">more details</a>
              </p>
            </div>
            {/* Add more service items as needed */}
          </div>
        </div>

        <div ref={this.contactRef}>
          <ContactUs />
        </div>
        <ScrolltoTop />
        <Footer isCorpPortal={true} />
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(CorporatePage);
