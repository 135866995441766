import React, { Component } from "react";
import HeaderNavBar from "../HeaderNavBar/HeaderNavBar";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import Footer from "../Footerpage/Footer";
import TranslationFile from "../Translation/Translation.json";
import "./AboutUs.css";

let userSelectedLanguage;

class WhyRebelSkool extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");

    window.scrollTo(0, 0);
  }

  callback = () => {
    this.setState({
      reRender: true,
    });
  };

  render() {
    return (
      <div>
        <HeaderNavBar isCorpPortal={true} callBack={this.callback}/>
        <div className="ID_About-Us">
          <br></br>
          <br></br>

          {/* ------------------------------------------------------------------ */}
          <div class="cor-product" ref={this.whyRSRef}>
            <div id="cor-product-section">
              <h2 className="cor-product-h2">
                {TranslationFile.FooterwhyRBL[userSelectedLanguage]}
              </h2>
              <p id="cor-product-paragraph">
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore Lorem ipsum dolor
                sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </div>
        <ScrolltoTop />
        <Footer />
      </div>
    );
  }
}

export default WhyRebelSkool;
